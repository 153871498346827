import React, { useCallback, useRef } from "react";
import { useShow } from "@mksap/components/hooks/useShow";
import { useUniqueId } from "@mksap/components/hooks/useUniqueId";
import { Overlay, Tooltip } from "../ui/MksapReactBootstrap";
import { closest } from "@mksap/utils/jQueryShim";

// This is a tooltip that displays on click and will hide on click or scroll.
// We had a similar version in 18. Hiding on scroll prevented the tooltip from
// appearing over top of the toolbar. This tooltip defaults to position top but
// will reset the position to bottom if the target is too close to the top app
// bar or modal window.
interface ClickableTooltipProps {
	content: React.ReactNode;
	children: React.ReactNode;
	onShow?(): void;
	onHide?(): void;
	className?: string;
}

export const ClickableTooltip: React.FC<ClickableTooltipProps> = ({
	content,
	children,
	onShow,
	onHide,
	className,
}) => {
	const { showing, show, hide } = useShow();
	const target = useRef<HTMLElement | null>(null);
	const uniqueId = useUniqueId();
	const scrollableEl = useRef<HTMLElement | null>(null);
	const customPlacement = useRef("top");
	const handleScroll = useCallback(() => hide(), [hide]);

	const handleClick = useCallback(() => {
		// Calculate the custom placement based on the target position at onclick
		if (target.current) {
			const targetPositions = target.current.getBoundingClientRect();
			if (targetPositions.top < 170) {
				customPlacement.current = "bottom";
			} else {
				customPlacement.current = "top";
			}
		}

		show();
	}, [target, show, customPlacement]);

	const handleEntered = useCallback(() => {
		// Assume we are in a related content modal
		scrollableEl.current = closest(target.current!, ".modal-body");

		// If we aren't in a related content modal, set the scrollable el to <main>
		if (!scrollableEl.current) {
			scrollableEl.current = document.querySelector("main");
		}

		// Add handler to scroll event which hides the tooltip on scroll
		if (scrollableEl.current) {
			scrollableEl.current.addEventListener("scroll", handleScroll);
		}
	}, [scrollableEl, handleScroll, target]);

	const handleExit = useCallback(() => {
		if (onHide) {
			onHide();
		}

		// Remove handler responsible for hiding tooltip on scroll
		if (scrollableEl.current) {
			scrollableEl.current.removeEventListener("scroll", handleScroll);
		}

		// Set custom placement to default
		customPlacement.current = "top";
	}, [onHide, scrollableEl, handleScroll, customPlacement]);

	return (
		<>
			<span onClick={handleClick} className={className} ref={target}>
				{children}
			</span>
			<Overlay
				aria-hidden="true"
				show={showing}
				target={target.current}
				rootClose={true}
				onEnter={onShow}
				onHide={hide}
				onEntered={handleEntered}
				onExit={handleExit}
				placement={customPlacement.current as any}
			>
				{(props) => (
					<Tooltip id={uniqueId} {...props}>
						{content}
					</Tooltip>
				)}
			</Overlay>
		</>
	);
};
